import { ReactNode, ReactElement } from 'react';

import SiteFurnitureContext, {
  SiteFurnitureContextValue,
} from 'lib/context/SiteFurnitureContext';

export type SiteFurnitreProviderProps = {
  children: ReactNode;
  value: SiteFurnitureContextValue;
};

const SiteFurnitureProvider = ({
  children,
  value,
}: SiteFurnitreProviderProps): ReactElement => {
  return (
    <SiteFurnitureContext.Provider value={value}>
      {children}
    </SiteFurnitureContext.Provider>
  );
};

export default SiteFurnitureProvider;
