import { gql } from '@apollo/client';

import { SiteFurniture } from 'types/app';

export const GET_APP_DOWNLOAD_INTERSTITIAL_DATA = gql`
  query getAppDownloadInterstitialData {
    getSiteFurnitureData @client
  }
`;

export type InterstitialData = {
  getSiteFurnitureData: SiteFurniture;
};
