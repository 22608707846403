import { ReactElement, useEffect, useState } from 'react';

import { isBrowser } from 'lib/utils/browser';

type ClientOnlyProps = {
  children: ReactElement;
};

export const ClientOnly = ({ children }: ClientOnlyProps) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    if (isBrowser()) {
      setIsClient(true);
    }
  }, []);

  return isClient ? children : null;
};

export default ClientOnly;
