import { gql } from '@apollo/client';

export const EMAIL_LIST_SIGN_UP = gql`
  mutation createEmailSignUp($input: EmailSignUpInput!) {
    createEmailSignUp(input: $input) {
      success
      validationErrors {
        field
        message
      }
    }
  }
`;
