import classnames from 'classnames';
import { AnchorHTMLAttributes, ReactElement } from 'react';

import { getLinkParts } from './utils';
import IDefaultComponentProps from 'components/defaultComponentProps';

import { ILocalizableLink } from 'lib/contentful';
import {
  BaseLink,
  BrandLink,
  CategoryLandingLink,
  CategoryLink,
  CollectionLink,
  DynamicPageLink,
  EditorialLink,
  HomeLink,
  TastemakersLink,
} from 'lib/links';
import { extractQueryParamsFromString } from 'lib/links/utils';
import Logger from 'lib/utils/Logger';

import styles from './LocalizableLink.module.scss';

export enum LinkTypes {
  Base = 'base',
  Brand = 'brand',
  Category = 'category',
  CategoryLanding = 'categoryLanding',
  ClickOnly = 'clickOnly',
  Collection = 'collection',
  DynamicPage = 'dynamicPage',
  Editorial = 'editorial',
  External = 'external',
  Home = 'home',
  Tastemakers = 'tastemakers',
}

export interface ILocalizableLinkProps
  extends ILocalizableLink,
    IDefaultComponentProps {
  children?: any;
  fontColor?: string;
  onClick?: () => void;
  tabIndex?: number;
}

export type LinkParts = {
  link:
    | typeof BaseLink
    | typeof BrandLink
    | typeof CategoryLink
    | typeof CategoryLandingLink
    | typeof CollectionLink
    | typeof DynamicPageLink
    | typeof EditorialLink
    | typeof HomeLink
    | typeof TastemakersLink;
  linkProps?: any;
};

const LocalizableLink = (
  linkProps: ILocalizableLinkProps
): ReactElement | null => {
  // TODO: Add ErrorBoundary here when available
  const {
    children,
    className,
    dataAttributes,
    displayText,
    fontColor,
    linkType,
    noFollow = false,
    onClick,
    queryString,
    tabIndex = 0,
  } = linkProps;

  const LABEL_TEXT = displayText || 'Link';

  const queryParams = extractQueryParamsFromString(queryString);

  const noFollowProperyVal = noFollow ? 'nofollow' : undefined;

  const anchorProps: AnchorHTMLAttributes<HTMLAnchorElement> = {
    'aria-label': LABEL_TEXT,
    className: classnames(styles.root, className),
    onClick,
    ...dataAttributes,
  };

  if (fontColor) {
    anchorProps.style = { color: fontColor };
  }

  const innerLink = (
    <a {...anchorProps} rel={noFollowProperyVal} tabIndex={tabIndex}>
      {children || displayText}
    </a>
  );

  switch (linkType) {
    case LinkTypes.Base:
    case LinkTypes.Brand:
    case LinkTypes.Category:
    case LinkTypes.CategoryLanding:
    case LinkTypes.Collection:
    case LinkTypes.DynamicPage:
    case LinkTypes.Editorial:
    case LinkTypes.Home:
    case LinkTypes.Tastemakers: {
      const linkParts = getLinkParts({
        linkProps,
        linkType,
        queryParams,
      });
      if (linkParts) {
        const { link: LinkComponent, linkProps: thisLinkProps } = linkParts;
        return <LinkComponent {...thisLinkProps}>{innerLink}</LinkComponent>;
      } else {
        Logger.info(`Unable to get linkParts for linkType '${linkType}'`);
        break;
      }
    }

    case LinkTypes.External:
      return (
        <a
          aria-label={LABEL_TEXT}
          className={classnames(styles.root, className)}
          href={linkProps.pathName}
          onClick={onClick}
          rel={noFollowProperyVal}
          tabIndex={tabIndex}
          {...dataAttributes}
        >
          {children || displayText}
        </a>
      );

    case LinkTypes.ClickOnly: {
      return innerLink;
    }

    default: {
      // Do not render unknown link types
      Logger.warn(
        `Unknown LocalizableLink linkType '${linkType}'; data: ${JSON.stringify(
          linkProps
        )}`
      );
      return null;
    }
  }

  return null;
};

export default LocalizableLink;
