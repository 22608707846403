import { useExperiments } from 'lib/experiment';
import { getAssetUrl } from 'lib/routes/metaTags';

import styles from './BugSubmissionButton.module.scss';

const BUG_SUBMISSION_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSdM_SXHhEjgOiRHFZfIfrVJbthvFcodXnKACS8yyjoXdsBbQA/viewform';

const BugSubmissionButton = () => {
  const { experiments: { showBugSubmissionButton } = {} } = useExperiments();

  if (!showBugSubmissionButton) {
    return null;
  }

  return (
    <div className={styles.root}>
      <a
        className={styles.link}
        href={BUG_SUBMISSION_URL}
        rel="noreferrer nofollow"
        target="_blank"
      >
        <img
          alt="Bug submission icon"
          className={styles.icon}
          src={getAssetUrl('images/bug_icon_transparent_24.png')}
        />
      </a>
    </div>
  );
};

export default BugSubmissionButton;
