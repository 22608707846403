const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;

export const addDays = (date: Date, days: number): Date | null => {
  try {
    const result: Date = new Date(date);
    const daysTotal: number = date.getDate() + days;
    result.setDate(daysTotal); // updates date of the month in place

    return result;
  } catch (error) {
    return null;
  }
};

export const getDaysBetween = (start: Date, end: Date): number => {
  const diff = end.getTime() - start.getTime();

  return Math.floor(diff / MILLISECONDS_PER_DAY);
};

export const getLocalTimeZoneCode = (): string | null => {
  try {
    return new Date()
      .toLocaleTimeString('en-us', { timeZoneName: 'short' })
      .split(' ')
      .reverse()[0];
  } catch (error) {
    return null;
  }
};
