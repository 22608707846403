import { ReactElement } from 'react';

import IDefaultComponentProps from 'components/defaultComponentProps';
import {
  SiteWideBannerUI,
  SiteWideBannerProps,
} from 'components/SiteWideBanner/SiteWideBanner';
import {
  hasLiveShopStarted,
  isLiveShopExpired,
} from 'explorer/containers/LiveShopContainer/utils';
import { useUpcomingLiveShopsData } from 'explorer/lib/hooks/useUpcomingLiveShopsData';

import { useSiteFurnitureContext } from 'lib/context/SiteFurnitureContext';
import { isBrowser } from 'lib/utils/browser/index';

import styles from './LiveShopBanner.module.scss';

const DEFAULT_BACKGROUND_COLOR = '#F1EDE5';
const FONT_COLOR = '#333333';

/** This component is not being used at the moment. */
const LiveShopBanner = (props: IDefaultComponentProps): ReactElement | null => {
  const { upcomingLiveShopsData = [] } = useUpcomingLiveShopsData({
    days: 1,
    skip: !isBrowser(),
  });

  const siteFurnitureData = useSiteFurnitureContext();

  if (siteFurnitureData.available === false) {
    return null;
  }

  const isUsingTwoContentfulBanners =
    siteFurnitureData.siteWideBanner2 && siteFurnitureData.siteWideBanner;

  const liveShopData = upcomingLiveShopsData?.[0];

  if (!liveShopData || isUsingTwoContentfulBanners) {
    return null;
  }

  const isExpired =
    isLiveShopExpired(liveShopData.confirmedEndsAt || liveShopData.endsAt) ||
    false;
  const hasStarted =
    hasLiveShopStarted(liveShopData.confirmedStartsAt) || false;
  const displayName =
    liveShopData.owner?.displayName || liveShopData.owner?.username || '';
  const eventName = liveShopData.title;

  let imageUrl;

  if (liveShopData.owner?.profileAsset?.type === 'IMAGE') {
    imageUrl = liveShopData.owner.profileAsset?.url;
  }

  const isLiveNow = hasStarted && !isExpired;

  const getHeader = (name: string, event: string) => {
    return (
      <span>
        <span>{name}</span>
        <span>{' is '}</span>
        <span className={styles.livestreamingText}>Livestreaming</span>
        <span>! </span>
        <span>{`Watch & shop from ${event}`}</span>
      </span>
    );
  };

  const getMobileHeader = (name: string) => {
    return (
      <span className={styles.livestreamHeaderContainer}>
        <span className={styles.truncatedUserName}>{name}</span>
        <span className={styles.spacedText}>{' is '}</span>
        <span className={styles.livestreamingText}>Livestreaming</span>
        <span>!</span>
      </span>
    );
  };

  if (!isLiveNow) {
    return null;
  }

  const bannerProps: SiteWideBannerProps = {
    backgroundColorCss: DEFAULT_BACKGROUND_COLOR,
    bannerLink: {
      displayText: 'Livestreams',
      linkType: 'base',
      pathName: '/live-shop',
    },
    ctaLinkText: 'Watch Now',
    fontColorCss: FONT_COLOR,
    header: getHeader(displayName, eventName),
    imageUrl,
    mobileHeader: getMobileHeader(displayName),
    suppressDismissIcon: true,
  };

  return <SiteWideBannerUI {...props} {...bannerProps} />;
};

export default LiveShopBanner;
