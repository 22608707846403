import { gql } from '@apollo/client';

export const GET_VARIANTS_FOR_USER_MENU = gql`
  query getVariantsForUserMenu($skus: [String]) {
    productVariant(skus: $skus) {
      productBundleItems {
        sku
        quantity
      }
      sku
    }
  }
`;

export type GetVariantsForUserMenuResponse = {
  productVariant: Array<{
    productBundleItems: Array<{
      quantity: number;
      sku: string;
    }>;
    sku: string;
  }>;
};
