import classnames from 'classnames';
import { KeyboardEvent, ReactElement, useCallback } from 'react';

import { Breakpoints } from 'lib/breakpoints';
import {
  GenerateImageSrcAttributesProps,
  generateImageSrcSet,
} from 'lib/image';
export { generateImageSrcSet } from 'lib/image';

import styles from './Image.module.scss';

export type ImageProps = {
  alt?: string;
  className?: string;
  imageContainerClassName?: string;
  isHidden?: boolean;
  isLazyLoad?: boolean;
  largeSrc?: string | GenerateImageSrcAttributesProps;
  mediumSrc?: string | GenerateImageSrcAttributesProps;
  onClick?: () => void;
  setImageLoaded?: () => void;
  smallSrc?: string | GenerateImageSrcAttributesProps;
  src: string | GenerateImageSrcAttributesProps;
  style?: { [classname: string]: string };
  xLargeSrc?: string | GenerateImageSrcAttributesProps;
};

const Image = (props: ImageProps): ReactElement => {
  const {
    alt,
    className,
    imageContainerClassName,
    isHidden,
    isLazyLoad,
    largeSrc,
    mediumSrc,
    onClick,
    setImageLoaded,
    smallSrc,
    src,
    style,
    xLargeSrc,
  } = props;

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && typeof onClick === 'function') {
      onClick();
    }
  };

  const handleImgOnLoad = useCallback(() => {
    if (typeof setImageLoaded === 'function') {
      setImageLoaded();
    }
  }, [setImageLoaded]);

  return (
    <picture className={imageContainerClassName} onLoad={handleImgOnLoad}>
      {xLargeSrc && (
        <source
          {...getSrcSetProps(xLargeSrc)}
          media={`(min-width: ${Breakpoints.widthExtraLarge}px)`}
        />
      )}

      {largeSrc && (
        <source
          {...getSrcSetProps(largeSrc)}
          media={`(min-width: ${Breakpoints.widthLarge}px)`}
        />
      )}

      {mediumSrc && (
        <source
          {...getSrcSetProps(mediumSrc)}
          media={`(min-width: ${Breakpoints.widthMedium}px)`}
        />
      )}

      {smallSrc && (
        <source
          {...getSrcSetProps(smallSrc)}
          media={`(min-width: ${Breakpoints.widthSmall}px)`}
        />
      )}

      <img
        {...getSrcSetProps(src)}
        alt={alt}
        className={classnames(className, { [styles.hideImage]: isHidden })}
        loading={isLazyLoad ? 'lazy' : 'eager'}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        onLoad={handleImgOnLoad}
        role="presentation"
        style={style}
      />
    </picture>
  );
};

const getSrcSetProps = (
  srcOptions: string | GenerateImageSrcAttributesProps
) => {
  if (typeof srcOptions === 'string') {
    return { srcSet: srcOptions };
  }

  return {
    srcSet: generateImageSrcSet(srcOptions),
  };
};

Image.defaultProps = {
  isLazyLoad: true,
};

export default Image;
