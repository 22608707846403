import { ApolloError } from '@apollo/client';

import { useSiteFurnitureContext } from 'lib/context/SiteFurnitureContext';
import Logger from 'lib/utils/Logger';

import { InfoBarProps } from 'types/app';

export interface UseInfoBarDataResult {
  error?: ApolloError;
  infoBarData?: InfoBarProps | null;
  loading: boolean;
}

export const useInfoBarData = (): UseInfoBarDataResult => {
  const siteFurnitureData = useSiteFurnitureContext();

  if (siteFurnitureData.available === false) {
    const error = new ApolloError({
      errorMessage: 'Failed to load site furniture from context value',
    });

    Logger.info('Failed to load site furniture', error);
    return {
      error,
      infoBarData: null,
      loading: false,
    };
  }

  return {
    error: undefined,
    infoBarData: siteFurnitureData.infoBar,
    loading: false,
  };
};
