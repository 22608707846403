import { useMutation } from '@apollo/client';
import { useEffect, useReducer } from 'react';

import { getAnonymousUserId } from 'lib/multiStorageWrapper/multiStorageWrapper';
import { isBrowser } from 'lib/utils/browser';
import Logger from 'lib/utils/Logger';

import { GET_APP_ONBOARDING_INVITE_CODE } from './inviteCode.gql';

import {
  MutationGenerateOnboardingInviteCodeArgs,
  WebGenerateOnboardingInviteCodeMutation,
} from 'types/generated/api';

export enum INVITE_CODE_SOURCE {
  APP_INSTALL_BANNER = 'app_install_banner',
  FBA = 'fba',
  POST_CAROUSEL = 'post_carousel',
  POST_PAGE = 'post_page',
}

/**
 * Query string param and value to show the FBA install banner
 * ad on the PDP page.
 */
export const FBA_APP_INSTALL_QUERY_PARAM = 'show_app_install=true';

const PARAM_INVITE_CODE_SOURCE = 'invite_code_source';

type TakeoverState = {
  didShow: boolean;
  inviteCode: string;
  isOpen: boolean;
};

type TakeoverAction =
  | { inviteCode: string; type: 'SHOW_TAKEOVER_WITH_INVITE_CODE' }
  | { type: 'CLOSE_TAKEOVER' };

type UseInviteCodeReturnValue = TakeoverState & {
  handleTakeoverClose: () => Promise<void>;
};

const initialState: TakeoverState = {
  didShow: false,
  inviteCode: '',
  isOpen: false,
};

const TakeoverReducer = (
  state: TakeoverState,
  action: TakeoverAction
): TakeoverState => {
  switch (action.type) {
    case 'CLOSE_TAKEOVER': {
      return {
        ...state,
        isOpen: false,
      };
    }

    case 'SHOW_TAKEOVER_WITH_INVITE_CODE': {
      return {
        ...state,
        didShow: true,
        inviteCode: action.inviteCode,
        isOpen: true,
      };
    }
    default: {
      return state;
    }
  }
};

// tech design spec: https://docs.google.com/document/d/1RCM_wmaQfwyV190MX2HTV-ZeFtVfjdWGLimJIDyWSQ4/edit
export const useInviteCode = (
  showAppInstall: boolean,
  source: INVITE_CODE_SOURCE,
  url?: string | null
): UseInviteCodeReturnValue => {
  const [state, dispatch] = useReducer(TakeoverReducer, initialState);
  const [getAppOnboardingInviteCode] = useMutation<
    WebGenerateOnboardingInviteCodeMutation,
    MutationGenerateOnboardingInviteCodeArgs
  >(GET_APP_ONBOARDING_INVITE_CODE);

  const handleTakeoverClose = async (): Promise<void> => {
    dispatch({ type: 'CLOSE_TAKEOVER' });
  };

  /** Effect get invite code for app install */
  useEffect(() => {
    const getInviteCode = async (): Promise<void> => {
      if (!url) {
        return;
      }
      const urlWithSource = new URL(url);
      urlWithSource.searchParams.set(PARAM_INVITE_CODE_SOURCE, source);
      const resp = await getAppOnboardingInviteCode({
        variables: {
          url: urlWithSource.toString(),
          userId: getAnonymousUserId(),
        },
      });
      if (resp.data?.generateOnboardingInviteCode?.success) {
        const inviteCode = resp.data.generateOnboardingInviteCode.code;
        dispatch({ inviteCode, type: 'SHOW_TAKEOVER_WITH_INVITE_CODE' });
      } else {
        Logger.error(
          `Error with GraphQL call generateOnboardingInviteCode with parameters with { url: ${url}, userId: ${getAnonymousUserId()}}`
        );
      }
    };

    const runEffect = async () => {
      if (showAppInstall && isBrowser() && !state.didShow) {
        try {
          await getInviteCode();
        } catch (error) {
          if (error instanceof Error) {
            Logger.error(error);
          }
        }
      }
    };
    runEffect();
  }, [getAppOnboardingInviteCode, showAppInstall, source, state.didShow, url]);

  return { ...state, handleTakeoverClose };
};
