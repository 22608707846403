import { gql } from '@apollo/client';

import {
  liveShopNoAuthFields,
  liveShopWithProductsNoAuthFields,
} from 'explorer/data/fragments.gql';

export const GET_LIVE_SHOP_DATA = gql`
  query getLiveShopData($inviteCode: String!) {
    liveShopByInviteCode(inviteCode: $inviteCode) {
      ...liveShopWithProductsNoAuthFields
    }
  }
  ${liveShopWithProductsNoAuthFields}
`;

export const GET_LIVE_SHOPS_BY_OWNER = gql`
  query getLiveShopsByOwner(
    $ownerId: String!
    $cursor: String
    $limit: Int
    $order: PaginationOrder
    $status: [LiveShopStatus]
  ) {
    liveShopsByOwner(
      ownerId: $ownerId
      cursor: $cursor
      limit: $limit
      order: $order
      status: $status
    ) {
      items {
        ...liveShopNoAuthFields
      }
    }
  }
  ${liveShopNoAuthFields}
`;

export const GET_UPCOMING_LIVE_SHOPS_DATA = gql`
  query getUpcomingLiveShopData(
    $bounds: LiveShopBounds!
    $limit: Int
    $order: PaginationOrder
    $privacy: [LiveShopPrivacy]
    $status: [LiveShopStatus]
  ) {
    liveShopsByDateBounded(
      bounds: $bounds
      limit: $limit
      order: $order
      privacy: $privacy
      status: $status
    ) {
      items {
        ...liveShopNoAuthFields
      }
    }
  }
  ${liveShopNoAuthFields}
`;
