import idx from 'idx';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';

import { NavigationMenuItemProps } from 'components/NavigationMenu/NavigationMenuItem';
import { INavigationSubmenuColumnProps } from 'components/NavigationMenu/NavigationSubmenuColumn';
import { NavigationSubmenuSectionProps } from 'components/NavigationMenu/NavigationSubmenuSection';

export const getSubmenuSectionsFromItem = (
  menuItem?: NavigationMenuItemProps | null
) => {
  if (!menuItem) {
    return null;
  }

  const columns = idx(menuItem, _ => _.submenu.columns);
  let sections: NavigationSubmenuSectionProps[] = [];

  if (!isEmpty(columns)) {
    sections = flatten(
      columns?.map((column: INavigationSubmenuColumnProps) => column.sections)
    ).filter(section => !isEmpty(section));
  }

  return sections;
};
