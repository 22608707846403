import { isBrowser } from 'lib/utils/browser';
import Logger from 'lib/utils/Logger';

/**
 * A wrapper that allows us to safely access window.VerishopMultiStorage.
 * window.VerishopMultiStorage is set inside of _document.tsx.
 */

// default to expire in 30 days.
export const EXPIRES_IN_30_DAYS_IN_UNIT_SECOND = 60 * 60 * 24 * 30;

export const getCookie: Window['VerishopMultiStorage']['getCookie'] = (
  key: string
): string | undefined => {
  try {
    if (isBrowser()) {
      return window.VerishopMultiStorage.getCookie(key);
    }
  } catch (error) {
    Logger.error('MultiStorage getCookie: ', error);
  }
};

export const setCookie: Window['VerishopMultiStorage']['setCookie'] = (
  key: string,
  value: string,
  expiresInSecond?: number
): void => {
  try {
    if (isBrowser()) {
      return window.VerishopMultiStorage.setCookie(key, value, expiresInSecond);
    }
  } catch (error) {
    Logger.error('MultiStorage setCookie: ', error);
  }
};
