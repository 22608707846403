import { gql } from '@apollo/client';

export const MARK_REWARDS_SEEN_MUTATION = gql`
  mutation webMarkRewardsSeenMutation($verifiedDealIds: [String!]!) {
    markVerifiedDealsAsSeen(verifiedDealIds: $verifiedDealIds) {
      success
      validationErrors {
        message
      }
    }
  }
`;
