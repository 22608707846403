import { gql } from '@apollo/client';

export const GET_LIVE_SHOPS_BY_IDS = gql`
  query getLiveShopsByIds($ids: [String!]!) {
    liveShopsByIds(ids: $ids) {
      id
      owner {
        username
      }
      title
    }
  }
`;
