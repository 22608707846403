import classnames from 'classnames';
import { ReactElement } from 'react';

import {
  DealEntryFromGetVerifiedDealsQuery,
  getPrimarySectionCopy,
  getRedemptionStateCopy,
  getSecondarySectionCopy,
  isOnlyAvailableOnApp,
  isRewardLocked,
  RedemptionState,
  generateRewardExpirationMessage,
} from './utils';
import ButtonBase, { BUTTON_SIZE } from 'components/Buttons/ButtonBase';
import { LiveShopDiscountDetail } from 'explorer/lib/hooks/useLiveShopDiscountDetail';

import { DEFAULT_IMAGE } from 'lib/routes/metaTags';

import styles from './Reward.module.scss';

type RewardProps = {
  className?: string;
  data: DealEntryFromGetVerifiedDealsQuery;
  disableRedeemButton: boolean;
  liveShopDiscountDetail?: LiveShopDiscountDetail;
  onRedeemOffer: (deal: DealEntryFromGetVerifiedDealsQuery) => void;
  redemptionState: RedemptionState;
};

export const Reward = ({
  className,
  data,
  disableRedeemButton,
  liveShopDiscountDetail,
  onRedeemOffer,
  redemptionState,
}: RewardProps): ReactElement | null => {
  if (!data) {
    return null;
  }

  const rewardExpirationMessage = generateRewardExpirationMessage(
    new Date(data.expiresAt),
    new Date(Date.now())
  );

  const onRedeemClick = (): void => {
    onRedeemOffer(data);
  };
  const isAppOnly = isOnlyAvailableOnApp(data?.type || '');

  const isDisabled =
    redemptionState === 'LOADING' ||
    redemptionState === 'APPLIED' ||
    redemptionState === 'DISABLED' ||
    isAppOnly ||
    isRewardLocked(data);

  const primarySectionCopy = getPrimarySectionCopy(
    data,
    liveShopDiscountDetail
  );
  const secondarySectionCopy = getSecondarySectionCopy(
    data,
    liveShopDiscountDetail
  );

  return (
    <div className={classnames(styles.offer, className)}>
      <div className={styles.offerLeft}>
        <img
          alt="verishop-reward"
          className={styles.offerLeftImage}
          src={DEFAULT_IMAGE}
        />
      </div>
      <div className={styles.offerRight}>
        <div className={styles.offerRightContent}>
          {data.value > 0 && primarySectionCopy}
          {secondarySectionCopy}
          <div className={styles.ctaContainer}>
            <ButtonBase
              className={styles.redeemButton}
              disabled={isDisabled || disableRedeemButton}
              isLoading={redemptionState === 'LOADING'}
              isPrimaryButton={redemptionState === 'AVAILABLE'}
              onClick={onRedeemClick}
              size={BUTTON_SIZE.SMALL}
            >
              {getRedemptionStateCopy(redemptionState)}
            </ButtonBase>
            {rewardExpirationMessage && (
              <span className={styles.expirationMessage}>
                {rewardExpirationMessage}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
