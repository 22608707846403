import classnames from 'classnames';
import React, { ReactElement } from 'react';

import styles from './CopyToClipboard.module.scss';

/**
 * <CopyToClipboard> wraps a "copy" button.
 * When the button is clicked, CopyToClipboard will copy props.value to the clipboard.
 *
 * CopyToClipboard creates a hidden <input> element which is used for storing
 * the value that will be copied.
 */

type CopyToClipboardProps = {
  children: React.ReactElement;
  onCopy?: () => void;
  value?: string;
};

class CopyToClipboard extends React.Component<CopyToClipboardProps> {
  hiddenInputNode: React.RefObject<HTMLInputElement> = React.createRef();

  handleClick = (): void => {
    const wasCopied = this.copy();

    if (wasCopied && this.props.onCopy) {
      this.props.onCopy();
    }
  };

  copy(): boolean {
    let wasCopied = false;

    const inputNode = this.hiddenInputNode.current;
    if (inputNode) {
      const range = document.createRange();
      range.selectNodeContents(inputNode);

      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
        wasCopied = document.execCommand('copy');
      }
    }

    return wasCopied;
  }

  render(): ReactElement {
    const { children, value } = this.props;

    return (
      <>
        <span className={styles.hiddenInput} ref={this.hiddenInputNode}>
          {value}
        </span>
        {React.cloneElement(children, {
          className: classnames(styles.copyButton, children.props.className),
          onClick: this.handleClick,
        })}
      </>
    );
  }
}

export default CopyToClipboard;
