import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import { Asset } from 'contentful';
import { useCallback, useEffect, useState } from 'react';

import {
  GET_APP_DOWNLOAD_INTERSTITIAL_DATA,
  InterstitialData,
} from './AppDownloadInterstitial.gql.skip';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import GifImage from 'components/GifImage/GifImage';
import Image from 'components/Image/Image';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';
import PageTakeover from 'components/PageTakeover/PageTakeover';

import { trackAppInstallOptedIn } from 'lib/analytics';
import { Breakpoints } from 'lib/breakpoints';
import { getUrlFromAsset } from 'lib/contentful/utils';
import { resizeImage } from 'lib/image';
import keyPressHandler from 'lib/ui/keyPress/keyPressHandler';
import { setOpenAppDownloadInterstitial } from 'lib/utils/browser';
import Logger from 'lib/utils/Logger';

import CloseIcon from 'assets/icons/ic-close.inline.svg';

import styles from './AppDownloadInterstitial.module.scss';

const LOGO_IMAGE_WIDTH = 56;

const AppDownloadInterstitial = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openInterstitial = useCallback(() => setIsOpen(true), []);
  const closeInterstitial = () => setIsOpen(false);

  // Adds a global function so the interstitial can be opened by GTM
  useEffect(() => {
    setOpenAppDownloadInterstitial(openInterstitial);
  }, [openInterstitial]);

  const { data, error, loading } = useQuery<InterstitialData>(
    GET_APP_DOWNLOAD_INTERSTITIAL_DATA
  );

  if (error) {
    Logger.error('Error fetching GET_APP_DOWNLOAD_INTERSTITIAL_DATA', error);
    return null;
  }

  const appDownloadInterstitialModel =
    data?.getSiteFurnitureData?.appDownloadInterstitial;

  if (!appDownloadInterstitialModel || loading) {
    return null;
  }

  const {
    backgroundImage,
    closeIconColor,
    closeText,
    closeTextColor,
    header,
    headerColor,
    link,
    logoImage,
    subHeader,
    subHeader2,
    subHeaderColor,
  } = appDownloadInterstitialModel;

  if (!isOpen) {
    // performance optimization - do not load any assets (images and large gifs)
    // until this compnents is requested to render at runtime (by GTM)
    return null;
  }

  return (
    <PageTakeover
      className={classnames(styles.root, styles.hideOnDesktop)}
      hideHeader
      isOpen={isOpen}
      onCloseClick={closeInterstitial}
    >
      <div className={styles.contentContainer}>
        {renderImageAsset(backgroundImage)}

        <CloseIcon
          aria-label="Close"
          className={styles.closeIcon}
          onClick={closeInterstitial}
          role="button"
          stroke={closeIconColor}
          tab-index={0}
        />

        <div className={styles.contentWrapper}>
          {renderLogoImageAsset(logoImage)}

          {header && (
            <div className={styles.header} style={{ color: headerColor }}>
              {header}
            </div>
          )}
          {subHeader && (
            <div className={styles.subHeader} style={{ color: subHeaderColor }}>
              {subHeader}
            </div>
          )}
          {subHeader2 && (
            <div className={styles.subHeader} style={{ color: subHeaderColor }}>
              {subHeader2}
            </div>
          )}
          {link && (
            <LocalizableLink
              {...link.fields}
              className={styles.link}
              onClick={trackAppInstallOptedIn}
            >
              <SecondaryButton className={styles.button}>
                {link.fields.displayText}
              </SecondaryButton>
            </LocalizableLink>
          )}
          {closeText && (
            <div
              className={styles.closeText}
              onClick={closeInterstitial}
              onKeyPress={keyPressHandler({ Enter: closeInterstitial })}
              role="button"
              style={{ color: closeTextColor }}
              tabIndex={0}
            >
              {closeText}
            </div>
          )}
        </div>
      </div>
    </PageTakeover>
  );
};

const renderImageAsset = (asset?: Asset) => {
  if (!asset) {
    return null;
  }

  const imageUrl = asset && getUrlFromAsset(asset);
  const imageType = asset?.fields?.file?.contentType;
  const altText = asset?.fields?.title;

  if (!imageUrl) {
    return null;
  }

  if (imageType === 'image/gif') {
    return (
      <GifImage
        altText={altText}
        className={styles.image}
        imageUrl={imageUrl}
      />
    );
  } else {
    return (
      <Image
        alt={altText}
        className={styles.image}
        src={{ url: imageUrl, width: Breakpoints.widthSmall }}
      />
    );
  }
};

const renderLogoImageAsset = (asset?: Asset) => {
  if (!asset) {
    return null;
  }

  const assetImageUrl = asset && getUrlFromAsset(asset);
  if (assetImageUrl) {
    const resizedImageUrl = resizeImage({
      quality: 1,
      url: assetImageUrl,
      width: LOGO_IMAGE_WIDTH,
    });

    return (
      <div
        className={styles.logoImage}
        style={{ backgroundImage: `url("${resizedImageUrl}")` }}
      />
    );
  }

  return null;
};

export default AppDownloadInterstitial;
