import { ApolloError } from '@apollo/client';

import { SideNavigationMenuBannerModuleProps } from 'components/contentfulModules/SideNavigationMenuBannerModule/SideNavigationMenuBannerModule';

import { useSiteFurnitureContext } from 'lib/context/SiteFurnitureContext';
import Logger from 'lib/utils/Logger';

import { SlimmedSiteNavV2 } from 'types/app';

export interface UseNavigationMenuDataResult {
  error?: ApolloError;
  loading: boolean;
  navigationDataV2?: SlimmedSiteNavV2[];
  sideNavigationMenuBanner?: SideNavigationMenuBannerModuleProps;
}

const useNavigationMenuData = (): UseNavigationMenuDataResult => {
  const siteFurnitureData = useSiteFurnitureContext();
  if (siteFurnitureData.available === false) {
    const error = new ApolloError({
      errorMessage:
        'useNavigationMenuData failed to get data from SiteFurnitureContext',
    });
    // TODO: Investigate why we are getting false siteFurnitureData.available
    // on /blank uri, we are getting 6 times useNavigationMenuData calls, two calls are available
    Logger.info(
      'useNavigationMenuData failed to get data from SiteFurnitureContext!'
    );

    return {
      error,
      loading: false,
    };
  }

  return {
    loading: false,
    navigationDataV2: siteFurnitureData.siteNavigationV2,
    sideNavigationMenuBanner: siteFurnitureData.sideNavigationMenuBanner,
  };
};

export default useNavigationMenuData;
