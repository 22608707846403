import { LOGIN_HEADER_TEXT } from 'components/authForms/LogInForm/LogInForm';
import { SIGNUP_HEADER_TEXT } from 'components/authForms/SignUpForm/SignUpForm';

import { AnalyticsSource } from 'lib/analytics/events';

export const getAuthModalHeader = (
  isLoginScreen: boolean,
  source?: string
): string => {
  let headerText = isLoginScreen ? LOGIN_HEADER_TEXT : SIGNUP_HEADER_TEXT;
  if (
    source &&
    [
      AnalyticsSource.CART_PAGE.toString(),
      AnalyticsSource.CART_PAGE_NO_RESULTS.toString(),
      AnalyticsSource.USER_MENU.toString(),
    ].includes(source)
  ) {
    headerText += ' to view your rewards';
  }
  return headerText;
};
