import { gql } from '@apollo/client';

export const GET_APP_ONBOARDING_INVITE_CODE = gql`
  mutation webGenerateOnboardingInviteCode($url: String!, $userId: String) {
    generateOnboardingInviteCode(url: $url, userId: $userId) {
      code
      success
    }
  }
`;
