import classnames from 'classnames';
import { ReactElement, useCallback, useEffect, useState } from 'react';

import PrimaryButton from 'components/Buttons/PrimaryButton';
import TextButton from 'components/Buttons/TextButton/TextButton';

import AnalyticsEvent from 'lib/analytics/events';
import { useAuthContext } from 'lib/context/AuthContext';
import { useExperiments } from 'lib/experiment';
import { ExperimentName } from 'lib/experiment/experimentConfig';
import { useAppInstall } from 'lib/hooks/appInstall';
import { INVITE_CODE_SOURCE, useInviteCode } from 'lib/hooks/inviteCode';
import { getAssetUrl } from 'lib/routes/metaTags';
import { setOpenAppBannerModal } from 'lib/utils/browser';

import CloseIcon from 'assets/icons/ic-close.inline.svg';

import styles from './AppBannerModal.module.scss';

const APP_BANNER_DISMISSED_STORAGE_KEY = 'isDismissedDownloadAppModal_0920';
const DEFAULT_HEADER = 'Download Verishop App Today';
const DEFAULT_TEXT = 'Only available on iOS.';

type AppBannerModalProps = {
  header?: string;
  text?: string;
};

export const AppBannerModalUI = ({
  header,
  text,
}: AppBannerModalProps): ReactElement => {
  const [isVisible, setVisible] = useState(false);
  const imageUrl = getAssetUrl(
    'images/logomark/v-dash-white-navy-bg-60x60.png'
  );

  const hideBanner = useCallback(() => {
    setVisible(false);
    sessionStorage.setItem(APP_BANNER_DISMISSED_STORAGE_KEY, '1');
  }, []);

  // Adds a global function so the interstitial can be opened by GTM
  useEffect(() => {
    const showBanner = () => {
      setVisible(true);
    };
    setOpenAppBannerModal(showBanner);
  }, []);

  const url = window ? window.location.href : undefined;
  const { inviteCode, isOpen } = useInviteCode(
    isVisible,
    INVITE_CODE_SOURCE.APP_INSTALL_BANNER,
    url
  );
  const { onCloseClick, onDownloadClick } = useAppInstall({
    inviteCode,
    isOpen,
    onClose: hideBanner,
    onCloseClickEvent: AnalyticsEvent.APP_INSTALL_DISMISS_BANNER,
    onDownloadClickEvent: AnalyticsEvent.APP_INSTALL_FROM_BANNER,
    onOpenEvent: AnalyticsEvent.APP_INSTALL_FROM_BANNER,
  });

  const altText = 'Verishop Logo';

  return (
    <div
      className={classnames(styles.container, {
        [styles.animateIn]: isVisible,
        [styles.animateOut]: !isVisible,
      })}
    >
      <div className={styles.mainContent}>
        <div className={styles.header}>
          <img alt={altText} className={styles.logo} src={imageUrl} />
          <div className={styles.message}>
            <h1 className={styles.subheader}>{header}</h1>
            <p className={styles.subtext}>{text}</p>
          </div>
          <TextButton className={styles.closeButton} onClick={onCloseClick}>
            <CloseIcon className={styles.closeIcon} />
          </TextButton>
        </div>
        <PrimaryButton
          className={classnames(
            styles.button,
            styles.primaryButton,
            styles.submitButton
          )}
          id="app_download"
          onClick={onDownloadClick}
        >
          Download the App
        </PrimaryButton>
        <TextButton
          className={styles.cancelButton}
          id="dismiss_app_download_banner"
          onClick={onCloseClick}
        >
          Not Now
        </TextButton>
      </div>
    </div>
  );
};

export const AppBannerModal = ({
  header,
  text,
}: AppBannerModalProps): ReactElement | null => {
  const { experiments: { enableAppDownloadBanner } = {} } = useExperiments(
    ExperimentName.enableAppDownloadBanner
  );
  const { currentUserId } = useAuthContext();

  // do not show for signed in user.
  if (!enableAppDownloadBanner || !!currentUserId) {
    return null;
  }

  return (
    <AppBannerModalUI
      header={header ?? DEFAULT_HEADER}
      text={text ?? DEFAULT_TEXT}
    />
  );
};

export default AppBannerModal;
