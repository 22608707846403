import classnames from 'classnames';
import { HTMLAttributes, ReactElement } from 'react';

import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { ILocalizableLink } from 'lib/contentful';

import styles from './FeaturedLink.module.scss';

export interface FeaturedLinkProps
  extends Pick<HTMLAttributes<HTMLElement>, 'className'> {
  fontColor?: string;
  linkProps: ILocalizableLink;
}

export const FeaturedLink = ({
  className,
  fontColor,
  linkProps,
}: FeaturedLinkProps): ReactElement | null => {
  if (!linkProps) {
    return null;
  }

  return (
    <LocalizableLink
      {...linkProps}
      className={classnames(styles.root, className)}
      fontColor={fontColor}
    />
  );
};
