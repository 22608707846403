import classnames from 'classnames';
import dynamic from 'next/dynamic';
import { HTMLAttributes, ReactElement, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import IconButton from 'components/Buttons/IconButton';

import { Breakpoints } from 'lib/breakpoints';
import { isBrowser } from 'lib/utils/browser';

import SearchIcon from 'assets/icons/ic-search.inline.svg';

import styles from './SearchBarContainer.module.scss';
import searchInputStyles from 'components/SearchBar/SearchInput.module.scss';

const SearchBar = dynamic(() => import('components/SearchBar/SearchBar'), {
  ssr: false,
});

const SearchBarErrorFallback = dynamic(
  () =>
    import('components/SearchBar/SearchBar').then(
      mod => mod.SearchBarErrorFallback
    ),
  { ssr: false }
);

export interface SearchBarContainerProps
  extends HTMLAttributes<HTMLDivElement> {
  searchTerm?: string | null;
}

export const SearchBarContainer = ({
  className,
  searchTerm,
}: SearchBarContainerProps): ReactElement | null => {
  const [isSearchShowing, setIsSearchShowing] = useState(false);
  const [searchBarIsShowing, setSearchBarIsShowing] = useState(false);
  const searchBoxContainerClasses = classnames(styles.searchBoxContainer, {
    [styles.searchBoxContainerExpanded]: isSearchShowing,
    [styles.searchBoxVisible]: searchBarIsShowing,
  });

  useEffect(() => {
    if (!isBrowser()) {
      return;
    }

    const shouldShowSearchBarOnLoad =
      window.innerWidth > Breakpoints.widthSmall;

    setIsSearchShowing(shouldShowSearchBarOnLoad);
  }, []);

  return (
    <div className={classnames(styles.root, className)}>
      <div className={searchBoxContainerClasses}>
        <ErrorBoundary FallbackComponent={SearchBarErrorFallback}>
          <SearchBar
            isSearchShowing={isSearchShowing}
            onCloseButtonClick={() => setSearchBarIsShowing(false)}
            searchTerm={searchTerm ?? undefined}
            setIsShowing={(isShowing: boolean) => setIsSearchShowing(isShowing)}
            shouldBeFocused={searchBarIsShowing}
          />
        </ErrorBoundary>
      </div>
      <IconButton
        IconComponent={SearchIcon}
        aria-label="Open Search"
        className={classnames(styles.searchBarOpenIcon, {
          [searchInputStyles.icon]: searchBarIsShowing,
        })}
        onClick={() => setSearchBarIsShowing(true)}
      />
    </div>
  );
};
