import classnames from 'classnames';

import { gifToVideoFile } from 'lib/image/utils';
import Logger from 'lib/utils/Logger';

import styles from './GifImage.module.scss';

export type GifImageProps = {
  altText: string;
  className?: string;
  imageUrl: string;
};

const GifImage = (props: GifImageProps) => {
  try {
    const { altText, className, imageUrl } = props;
    const { mp4Url, webmUrl } = gifToVideoFile(imageUrl);
    const imageClassNames = classnames(styles.image, className);
    return (
      <picture>
        <source
          className={imageClassNames}
          srcSet={webmUrl}
          type="image/webm"
        />
        <source className={imageClassNames} srcSet={mp4Url} type="video/mp4" />
        <img alt={altText} className={imageClassNames} src={imageUrl} />
      </picture>
    );
  } catch (error) {
    Logger.warn('Unable to render GifImage due to an error.', error);
  }
  return null;
};

export default GifImage;
