import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useReducer } from 'react';

import {
  DealsFromGetVerifiedDealsQuery,
  DealEntryFromGetVerifiedDealsQuery,
  getRewardToTease,
  isRewardLocked,
} from 'components/Modals/RewardCenterModal/utils';
import {
  applyGiftCardToCurrentCheckout,
  getCartDetails,
  removeGiftCardFromCurrentCheckout,
} from 'data/graphql/resolvers/shopify';

import { checkIsUserLoggedIn } from 'lib/auth';
import { isBrowser } from 'lib/utils/browser';

import {
  RewardCenterModalQuery,
  RedeemRewardMutation,
  SaveAppliedShopifyGiftCardIdMutation,
} from './useRewards.gql';

import { CartLineItem as CartLineItemType } from 'data/graphql/types';
import {
  VerifiedDeal,
  WebOfferCenterGetVerifiedDealsQuery,
  WebOfferCenterGetVerifiedDealsQueryVariables,
  WebSaveAppliedShopifyGiftCardIdMutation,
  WebSaveAppliedShopifyGiftCardIdMutationVariables,
  RedeemVerifiedDealMutation,
  MutationRedeemVerifiedDealArgs,
} from 'types/generated/api';

export const APPLIED_OFFER_STORAGE_KEY = 'verishop_current_applied_offer';

type UseOffersState = {
  appliedOffer?: DealEntryFromGetVerifiedDealsQuery;
  currentPhase: 'FINISHED' | 'REQUESTING' | 'PARSING' | 'REFRESHING';
  didRunUserAuthenticatedCheck: boolean;
  errorMessage: string;
  loading: boolean;
  redeemOfferState: 'LOADING' | 'NONE' | 'REDEEMED';
  redeemableOffers: DealsFromGetVerifiedDealsQuery;
  userAuthenticated: boolean;
};

type UseOffersReturnValue = UseOffersState & {
  onRedeemOffer: (offer: DealEntryFromGetVerifiedDealsQuery) => Promise<void>;
  /**
   * Removes an applied reward
   * If working with an unsynced state in a different component,
   * you can manually pass in `giftCardId` by attempting to
   * retrieve the currently applied reward with `localStorage.getItem(APPLIED_OFFER_STORAGE_KEY)`
   * to force removal of the reward.
   */
  removeAppliedReward: (giftCardId?: string) => Promise<void>;
  resetUseRewards: () => void;
};

type UseOffersAction =
  | {
      type: 'CHANGE_REDEMPTION_STATE';
      value: 'FINISHED' | 'REQUESTING' | 'PARSING' | 'REFRESHING';
    }
  | { type: 'SET_APPLIED_OFFER'; value: VerifiedDeal }
  | { type: 'SET_EXTERNAL_LOAD_STATE'; value: boolean }
  | {
      appliedOffer?: DealEntryFromGetVerifiedDealsQuery;
      dealList: DealsFromGetVerifiedDealsQuery;
      type: 'FINISH_OFFER_VALIDATION';
    }
  | {
      type: 'REDEEMING_NEW_OFFER';
      value: DealEntryFromGetVerifiedDealsQuery;
    }
  | { type: 'SET_REDEEM_OFFER_STATE'; value: 'LOADING' | 'NONE' | 'REDEEMED' }
  | { type: 'SET_ERROR_MESSAGE'; value: string }
  | { type: 'REMOVE_APPLIED_REWARD' }
  | { type: 'RESET' }
  | { type: 'SET_USER_AUTH_STATE'; value: boolean };

const initialState: UseOffersState = {
  currentPhase: 'REQUESTING',
  didRunUserAuthenticatedCheck: false,
  errorMessage: '',
  loading: true,
  redeemOfferState: 'NONE',
  redeemableOffers: [],
  userAuthenticated: false,
};

const useRewardsReducer = (
  state: UseOffersState,
  action: UseOffersAction
): UseOffersState => {
  switch (action.type) {
    case 'CHANGE_REDEMPTION_STATE': {
      return {
        ...state,
        currentPhase: action.value,
      };
    }
    case 'SET_APPLIED_OFFER': {
      return {
        ...state,
        appliedOffer: action.value,
      };
    }

    case 'SET_EXTERNAL_LOAD_STATE': {
      return {
        ...state,
        loading: action.value,
      };
    }

    case 'FINISH_OFFER_VALIDATION': {
      return {
        ...state,
        appliedOffer: action.appliedOffer && { ...action.appliedOffer },
        currentPhase: 'FINISHED',
        loading: false,
        redeemOfferState: action.appliedOffer ? 'REDEEMED' : 'NONE',
        redeemableOffers: [...action.dealList],
      };
    }

    case 'REDEEMING_NEW_OFFER': {
      return {
        ...state,
        appliedOffer: action.value ? { ...action.value } : undefined,
        redeemOfferState: 'LOADING',
      };
    }

    case 'SET_REDEEM_OFFER_STATE': {
      return {
        ...state,
        redeemOfferState: action.value,
      };
    }

    case 'SET_ERROR_MESSAGE': {
      return {
        ...state,
        errorMessage: action.value,
      };
    }

    case 'RESET': {
      return {
        ...initialState,
        currentPhase: 'REFRESHING',
      };
    }

    case 'REMOVE_APPLIED_REWARD': {
      return {
        ...state,
        appliedOffer: undefined,
      };
    }

    case 'SET_USER_AUTH_STATE': {
      return {
        ...state,
        didRunUserAuthenticatedCheck: true,
        userAuthenticated: action.value,
      };
    }

    default: {
      return state;
    }
  }
};

/**
 * Retrieves the applied offer's Shopify Gift Card ID from either local
 * state or localStorage
 * @param state Current hook state
 * @returns Shopify gift card ID or undefined
 */
const getGiftCardId = (state: UseOffersState): string | undefined => {
  if (state?.appliedOffer?.appliedShopifyGiftCardId) {
    return state.appliedOffer.appliedShopifyGiftCardId;
  }
  const storedReward = localStorage.getItem(APPLIED_OFFER_STORAGE_KEY);
  if (storedReward) {
    const parsedReward = JSON.parse(
      storedReward
    ) as DealEntryFromGetVerifiedDealsQuery;
    if (parsedReward?.appliedShopifyGiftCardId) {
      return parsedReward.appliedShopifyGiftCardId;
    }
  }
};

/**
 * A custom hook that retrieves all qualified offers the current
 * user with current cart line items qualifies for
 * @param runCondition A boolean value that ensures the data fetching
 * only runs when this value is true to ensure no hanging
 * processes on component load
 * @param lineItems Cart line items to determine what offers
 * the user is eligible to redeem
 * @param onOfferRedeemedSuccess Callback one the verified deal when it's successfully redeemed
 * @returns UseOffersHookState
 */
export const useRewards = (
  runCondition: boolean,
  lineItems?: CartLineItemType[],
  onOfferRedeemedSuccess?: (
    deal: DealEntryFromGetVerifiedDealsQuery
  ) => Promise<void> | void
): UseOffersReturnValue => {
  const [state, dispatch] = useReducer(useRewardsReducer, initialState);
  const shouldRun = runCondition && isBrowser() && state.userAuthenticated;

  const { data, refetch } = useQuery<
    WebOfferCenterGetVerifiedDealsQuery,
    WebOfferCenterGetVerifiedDealsQueryVariables
  >(RewardCenterModalQuery, {
    fetchPolicy: 'network-only',
    skip: !shouldRun,
  });

  const [redeemOffer] = useMutation<
    RedeemVerifiedDealMutation,
    MutationRedeemVerifiedDealArgs
  >(RedeemRewardMutation);

  const [saveAppliedGiftCardId] = useMutation<
    WebSaveAppliedShopifyGiftCardIdMutation,
    WebSaveAppliedShopifyGiftCardIdMutationVariables
  >(SaveAppliedShopifyGiftCardIdMutation);

  useEffect(() => {
    const getAuthState = async (): Promise<void> => {
      const isLoggedIn = await checkIsUserLoggedIn();
      dispatch({
        type: 'SET_USER_AUTH_STATE',
        value: isLoggedIn,
      });
    };

    if (state.didRunUserAuthenticatedCheck === false) {
      getAuthState();
    }
  }, [state.didRunUserAuthenticatedCheck]);

  if (state.currentPhase === 'REFRESHING') {
    refetch().then(() =>
      dispatch({ type: 'CHANGE_REDEMPTION_STATE', value: 'REQUESTING' })
    );
  }

  const onRedeemOffer = async (
    offer: DealEntryFromGetVerifiedDealsQuery
  ): Promise<void> => {
    if (!offer) {
      return;
    }
    dispatch({ type: 'REDEEMING_NEW_OFFER', value: offer });

    try {
      const storedAppliedOffer = localStorage.getItem(
        APPLIED_OFFER_STORAGE_KEY
      );

      if (storedAppliedOffer) {
        const parsedOffer = JSON.parse(storedAppliedOffer) as VerifiedDeal;
        const { appliedShopifyGiftCardId } = parsedOffer;
        if (!appliedShopifyGiftCardId) {
          throw Error(
            'Cannot find currently applied gift card ID from stored deal'
          );
        }
        await removeGiftCardFromCurrentCheckout(appliedShopifyGiftCardId);
        localStorage.removeItem(APPLIED_OFFER_STORAGE_KEY);
      }

      const cart = await getCartDetails();
      const currentAppliedGiftCards = cart.appliedGiftCards.map(
        giftCard => giftCard.id
      );

      const mutationLineItems = (lineItems || []).map(lineItem => {
        return {
          price: Number(lineItem.price),
          productVariant: { sku: lineItem.sku },
          quantity: lineItem.quantity,
        };
      });

      const redemptionResponse = await redeemOffer({
        variables: {
          input: {
            lineItems: mutationLineItems,
            verifiedDealId: offer.id,
          },
        },
      });

      const verifiedDealGiftCardCode =
        redemptionResponse.data?.redeemVerifiedDeal.shopifyGiftCardCode;

      let updatedGiftCards: string[] = [];
      if (verifiedDealGiftCardCode) {
        const applyGiftCardResponse = await applyGiftCardToCurrentCheckout(
          verifiedDealGiftCardCode
        );
        updatedGiftCards =
          applyGiftCardResponse?.data?.checkoutGiftCardsAppend?.checkout?.appliedGiftCards?.map(
            x => x.id
          ) || [];
      }

      const appliedGiftCardId = updatedGiftCards.reduce(
        (acc, value: string) => {
          if (currentAppliedGiftCards.includes(value) || acc) {
            return acc;
          }
          return value;
        },
        ''
      );

      if (appliedGiftCardId) {
        await saveAppliedGiftCardId({
          variables: {
            input: {
              appliedShopifyGiftCardId: appliedGiftCardId,
              verifiedDealId: offer.id,
            },
          },
        });
      } else {
        dispatch({
          type: 'SET_ERROR_MESSAGE',
          value: 'There was an error redeeming your offer. Please try again',
        });
        dispatch({ type: 'SET_REDEEM_OFFER_STATE', value: 'NONE' });

        return;
      }

      if (localStorage && appliedGiftCardId && verifiedDealGiftCardCode) {
        localStorage.setItem(
          APPLIED_OFFER_STORAGE_KEY,
          JSON.stringify({
            ...offer,
            appliedShopifyGiftCardId: appliedGiftCardId,
            cartId: cart.id,
          })
        );
        dispatch({ type: 'SET_REDEEM_OFFER_STATE', value: 'REDEEMED' });
        onOfferRedeemedSuccess?.(offer);
      }
    } catch (error) {
      dispatch({
        type: 'SET_REDEEM_OFFER_STATE',
        value: 'NONE',
      });
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        value: 'There was an error redeeming your offer. Please try again',
      });
    }
  };

  const removeAppliedReward = async (giftCardId?: string): Promise<void> => {
    const shopifyGiftCardId = giftCardId || getGiftCardId(state);
    dispatch({ type: 'SET_EXTERNAL_LOAD_STATE', value: true });

    if (!shopifyGiftCardId) {
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        value: 'No applied reward found',
      });
      dispatch({ type: 'SET_EXTERNAL_LOAD_STATE', value: false });
      return;
    }

    try {
      await removeGiftCardFromCurrentCheckout(shopifyGiftCardId);
    } catch (error) {
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        value: `There was an error removing the reward from Shopify: ${error.message}`,
      });
      dispatch({ type: 'SET_EXTERNAL_LOAD_STATE', value: false });
      return;
    }

    localStorage.removeItem(APPLIED_OFFER_STORAGE_KEY);
    dispatch({ type: 'REMOVE_APPLIED_REWARD' });
    dispatch({ type: 'SET_EXTERNAL_LOAD_STATE', value: false });
  };

  const resetUseRewards = () => {
    dispatch({ type: 'RESET' });
  };

  // Parse rewards coming back
  if (shouldRun && data && state.currentPhase === 'REQUESTING' && lineItems) {
    dispatch({ type: 'CHANGE_REDEMPTION_STATE', value: 'PARSING' });

    getCartDetails().then(cart => {
      const { appliedGiftCards } = cart;

      const rawDeals = data.verifiedDeals.deals;

      if (!rawDeals && !Array.isArray(rawDeals)) {
        throw Error('Did not receive any deals');
      }

      const availableOffers = rawDeals.filter(
        offer => offer && !isRewardLocked(offer) && offer?.status === 'UNUSED'
      );

      // Only show/tease the reward that will unlock the soonest
      const lockedRewardToTease = getRewardToTease(rawDeals);

      const appliedOffer = availableOffers.find(offer => {
        return appliedGiftCards.find(giftCard => {
          return giftCard.id === offer?.appliedShopifyGiftCardId;
        });
      });

      dispatch({
        appliedOffer: appliedOffer as VerifiedDeal,
        dealList: lockedRewardToTease
          ? availableOffers.concat([lockedRewardToTease])
          : availableOffers,
        type: 'FINISH_OFFER_VALIDATION',
      });
    });
  }
  return { ...state, onRedeemOffer, removeAppliedReward, resetUseRewards };
};
