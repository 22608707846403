import isEmpty from 'lodash/isEmpty';
import dynamic from 'next/dynamic';
import { ReactElement, useEffect, useState } from 'react';

const OpenMenuContainerPortal = dynamic(
  () => import('./OpenMenuContainerPortal'),
  {
    ssr: false,
  }
);
import { renderSubmenuContent } from './SectionSubmenu';
import { getSubmenuSectionsFromItem } from './utils';
import IconButton from 'components/Buttons/IconButton';
import { SideNavigationMenuBannerModuleProps } from 'components/contentfulModules/SideNavigationMenuBannerModule/SideNavigationMenuBannerModule';
import IDefaultComponentProps from 'components/defaultComponentProps';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';
import { NavigationMenuItemProps } from 'components/NavigationMenu/NavigationMenuItem';

import { ILocalizableLink } from 'lib/contentful';
import { isBrowser } from 'lib/utils/browser';

import { SlimmedSiteNavV2 } from 'types/app';

import MenuIcon from 'assets/icons/ic-menu.inline.svg';

import styles from './NavigationMenuMobile.module.scss';

const renderNestedSEOMainMenuItem = (
  menuItem: NavigationMenuItemProps,
  index: number
): ReactElement | null => {
  if (isEmpty(menuItem)) {
    return null;
  }
  const submenuSections = getSubmenuSectionsFromItem(menuItem) || [];

  return (
    <li key={`${menuItem.id}_${index}`}>
      <LocalizableLink {...menuItem.link} />
      {!isEmpty(submenuSections) && submenuSections?.map(renderSubmenuContent)}
    </li>
  );
};

export const renderSEOLinks = (
  menuItems: NavigationMenuItemProps[]
): ReactElement | null => {
  if (isEmpty(menuItems)) {
    return null;
  }

  return (
    <nav className={styles.seoMenu} hidden>
      <ul>{menuItems.map(renderNestedSEOMainMenuItem)}</ul>
    </nav>
  );
};

export type NavigationMenuMobileProps = {
  featuredLink?: ILocalizableLink;
  fontColor?: string;
  isOpen?: boolean;
  menuItems: SlimmedSiteNavV2[];
  sideNavigationMenuBanner?: SideNavigationMenuBannerModuleProps;
} & Partial<IDefaultComponentProps>;

const NavigationMenuMobile = ({
  className,
  featuredLink,
  fontColor,
  menuItems,
  sideNavigationMenuBanner = {},
}: NavigationMenuMobileProps): ReactElement | null => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isBrowser()) {
      document.body.style.overflow = isOpen ? 'hidden' : '';
    }
  }, [isOpen]);

  const openMenu = (): void => {
    setIsOpen(true);
  };

  const closeMenu = (): void => {
    setIsOpen(false);
  };

  return (
    <div className={className}>
      <IconButton
        IconComponent={MenuIcon}
        aria-expanded={isOpen}
        aria-label="Open Main Menu"
        className={styles.openMenuButton}
        onClick={openMenu}
      />

      {isOpen && (
        <OpenMenuContainerPortal
          closeMenuCallback={closeMenu}
          featuredLink={featuredLink}
          fontColor={fontColor}
          isOpen={isOpen}
          menuItems={menuItems}
          sideNavigationMenuBanner={sideNavigationMenuBanner}
        />
      )}
      {renderSEOLinks(menuItems)}
    </div>
  );
};

export default NavigationMenuMobile;
