import classnames from 'classnames';
import { HTMLAttributes, ReactElement } from 'react';

import IconButton from 'components/Buttons/IconButton';

import { generateThumbUrl } from 'lib/image';
import { getAssetUrl } from 'lib/routes/metaTags';

import EditProfileIcon from 'assets/icons/ic-edit-profile.inline.svg';

import styles from './ProfilePhoto.module.scss';

export interface ProfilePhotoProps extends HTMLAttributes<HTMLDivElement> {
  altText?: string;
  className?: string;
  onClickEdit?: () => void;
  placeholderAssetUrl?: string;
  profilePhotoUrl?: string;
  width?: number;
  zoom?: number;
}

// Default to placeholderAssetUrl, if profilePhotoUrl is empty. If placeholderAssetUrl is empty, use icons/ic-profile-placeholder.png.
export const ProfilePhoto = ({
  altText,
  className,
  onClickEdit,
  placeholderAssetUrl,
  profilePhotoUrl,
  width = 36,
  zoom,
}: ProfilePhotoProps): ReactElement | null => {
  const placeholder = placeholderAssetUrl ?? 'icons/ic-profile-placeholder.png';

  // Do not process url for dataUrl.
  const imageSrc = profilePhotoUrl
    ? profilePhotoUrl?.startsWith('data:image')
      ? profilePhotoUrl
      : generateThumbUrl({
          height: width,
          url: profilePhotoUrl,
          width,
          zoom,
        })
    : getAssetUrl(placeholder);

  return (
    <div
      className={classnames(styles.root, className)}
      style={{ height: width, width }}
    >
      <div
        className={classnames(
          styles.imageContainer,
          'profilePhotoImageContainer'
        )}
      >
        <img
          alt={altText}
          className={classnames(styles.image, 'profilePhotoImage')}
          src={imageSrc}
        />
        {onClickEdit && (
          <IconButton
            IconComponent={EditProfileIcon}
            aria-label="Edit Profile Photo"
            className={styles.editButton}
            onClick={onClickEdit}
          />
        )}
      </div>
    </div>
  );
};
