import gql from 'graphql-tag';

const fragments = {
  postsFields: gql`
    fragment postsFields on ExplorerPost {
      assets {
        dimensions {
          height
          width
        }
        id
        type
        url
      }
      description
      headline
      id
      modules {
        type
        ... on ExplorerProductsListModule {
          productVariants {
            sku
            galleryAssets {
              type
              url
            }
          }
          skus
        }
      }
      profile {
        id
        brandSlug
        profileAsset {
          url
        }
        type
        username
      }
      type
      transcriptUrl
    }
  `,
};

export const liveShopOwnerFields = gql`
  fragment liveShopOwnerFields on Profile {
    brandSlug
    displayName
    profileAsset {
      url
      type
    }
    type
    username
  }
`;

export const liveShopWithProductsNoAuthFields = gql`
  fragment liveShopWithProductsNoAuthFields on LiveShop {
    confirmedEndsAt
    confirmedStartsAt
    coverAsset {
      url
      type
    }
    description
    endsAt
    id
    inviteCodes {
      inviteCode
      type
    }
    owner {
      ...liveShopOwnerFields
    }
    featuredProducts {
      productVariant {
        compareAtPrice
        galleryAssets {
          url
        }
        price
        product {
          brand
          brandSlug
          familySlug
          sid
          slug
          title
        }
        sku
        title
      }
    }
    startsAt
    status
    teaserAsset {
      url
      type
    }
    title
  }
  ${liveShopOwnerFields}
`;

export const liveShopNoAuthFields = gql`
  fragment liveShopNoAuthFields on LiveShop {
    confirmedEndsAt
    confirmedStartsAt
    coverAsset {
      url
      type
    }
    description
    endsAt
    inviteCodes {
      inviteCode
      type
    }
    owner {
      ...liveShopOwnerFields
    }
    startsAt
    status
    teaserAsset {
      url
      type
    }
    title
  }
  ${liveShopOwnerFields}
`;

export default fragments;
