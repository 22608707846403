import {
  LinkParts,
  LinkTypes,
} from 'components/LocalizableLink/LocalizableLink';

import {
  BaseLink,
  BrandLink,
  CategoryLandingLink,
  CategoryLink,
  CollectionLink,
  DynamicPageLink,
  EditorialLink,
  HomeLink,
  TastemakersLink,
} from 'lib/links';
import Logger from 'lib/utils/Logger';

type LinkPartsProps = {
  linkProps: never;
  linkType: LinkTypes;
  navigateToClp?: {
    slugs?: string[] | undefined;
  };
  queryParams?: unknown;
};

/**
 * Since link entities take different props, we translate them here
 * You may spread in props from a LocalizableLink's fields
 */

export const getLinkParts = ({
  linkProps,
  linkType,
  queryParams,
}: LinkPartsProps): LinkParts | null => {
  try {
    const { slug } = linkProps;
    switch (linkType) {
      case LinkTypes.Base:
        const { pathName } = linkProps;
        return {
          link: BaseLink,
          linkProps: {
            pathName,
          },
        };

      case LinkTypes.Brand:
        const { startsWith } = linkProps;
        return {
          link: BrandLink,
          linkProps: {
            brandSlug: slug,
            queryParams,
            startsWith,
          },
        };

      case LinkTypes.Category:
        if (!slug) {
          Logger.info("Unable to generate CategoryLink, 'slug' not passed in");
          return null;
        }
        return {
          link: CategoryLink,
          linkProps: {
            categorySlug: slug,
            queryParams,
          },
        };

      case LinkTypes.CategoryLanding:
        if (!slug) {
          Logger.warn(
            "Unable to generate CategoryLandingLink, 'slug' not passed in"
          );
          return null;
        }
        return {
          link: CategoryLandingLink,
          linkProps: {
            categorySlug: slug,
            queryParams,
          },
        };

      case LinkTypes.Collection:
        if (!slug) {
          Logger.warn(
            "Unable to generate CollectionLink, 'slug' not passed in"
          );
          return null;
        }
        return {
          link: CollectionLink,
          linkProps: {
            collectionSlug: slug,
            queryParams,
          },
        };

      case LinkTypes.DynamicPage:
        if (!slug) {
          Logger.warn(
            "Unable to generate DynamicPageLink, 'slug' not passed in"
          );
          return null;
        }
        return {
          link: DynamicPageLink,
          linkProps: {
            pageSlug: slug,
          },
        };

      case LinkTypes.Editorial:
        if (!slug) {
          Logger.warn("Unable to generate EditorialLink, 'slug' not passed in");
          return null;
        }
        return {
          link: EditorialLink,
          linkProps: {
            slug,
          },
        };

      case LinkTypes.Home: {
        return {
          link: HomeLink,
        };
      }

      case LinkTypes.Tastemakers: {
        return {
          link: TastemakersLink,
          linkProps: {
            slug,
          },
        };
      }
    }
    Logger.warn(`LinkParts not defined for linkType: '${linkType}'`);
    return null;
  } catch (error) {
    Logger.warn(`Error encountered generating linkType: '${linkType}'`, error);
    return null;
  }
};
