import { ApolloError, useQuery } from '@apollo/client';

import Logger from 'lib/utils/Logger';

import { GET_LIVE_SHOPS_BY_IDS } from './useLiveShopDiscountDetail.gql';

import { GetLiveShopsByIdsQuery } from 'types/generated/api';

export type UseLiveShopDiscountDetailProps = {
  ids: string[];
};

export type LiveShopDiscountDetail = {
  liveShopId: string;
  streamer: string;
  title: string;
};

export type UseLiveShopDiscountDetailResult = {
  error?: ApolloError;
  liveShopDiscountDetails: Record<string, LiveShopDiscountDetail>;
  loading: boolean;
};

/**
 * A custom hook to fetch details for multiple live shops given the ids
 * @param ids live shop ids to fetch detail for
 * @returns live shop details
 */
export const useLiveShopDiscountDetail = ({
  ids,
}: UseLiveShopDiscountDetailProps): UseLiveShopDiscountDetailResult => {
  const { data, error, loading } = useQuery<GetLiveShopsByIdsQuery>(
    GET_LIVE_SHOPS_BY_IDS,
    {
      skip: !ids.length,
      variables: { ids },
    }
  );

  if (error) {
    Logger.error('Unexpected error in useLiveShopDiscountDetail', error);
  }

  return {
    error,
    liveShopDiscountDetails: toLiveShopDiscountDetail(data),
    loading,
  };
};

const toLiveShopDiscountDetail = (
  data?: GetLiveShopsByIdsQuery
): Record<string, LiveShopDiscountDetail> => {
  const result: Record<string, LiveShopDiscountDetail> = {};

  data?.liveShopsByIds?.forEach(item => {
    result[item.id] = {
      liveShopId: item.id,
      streamer: item.owner.username || '',
      title: item.title,
    };
  });

  return result;
};
