import isEmpty from 'lodash/isEmpty';

import Logger from 'lib/utils/Logger';

export const extractQueryParamsFromString: any = (queryString: string) => {
  const queryParams: any = {};
  if (!isEmpty(queryString)) {
    queryString.split('&').map((param: string) => {
      try {
        const split = param.split('=');
        queryParams[`${split[0]}`] = split[1];
      } catch (error) {
        Logger.error('Error trying to extract query param from string.', error);
      }
    });
  }
  return queryParams;
};
