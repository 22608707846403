import cn from 'classnames';
import { FC } from 'react';

import { RewardList } from './RewardList';
import { DealEntryFromGetVerifiedDealsQuery } from './utils';

import { CartLineItem as CartLineItemType } from 'data/graphql/types';

import styles from './RewardCenterModal.module.scss';

import Modal, { ModalProps } from '../Modal/Modal';

export type RewardCenterModalProps = Omit<ModalProps, 'children'> & {
  lineItems?: CartLineItemType[];
  onOfferRedeemed?: (
    deal: DealEntryFromGetVerifiedDealsQuery
  ) => Promise<void> | void;
  onOffersMarkedAsSeen?: (
    rewards: DealEntryFromGetVerifiedDealsQuery[]
  ) => void;
};

export const RewardCenterModal: FC<RewardCenterModalProps> = ({
  className,
  isOpen,
  lineItems,
  onCloseClick,
  onOfferRedeemed,
  onOffersMarkedAsSeen,
}: RewardCenterModalProps) => {
  return (
    <Modal
      className={cn(styles.root, className)}
      isOpen={isOpen}
      onCloseClick={onCloseClick}
    >
      <div className={styles.heading}>
        <h2 className={styles.headline}>Your Available Rewards</h2>
        <p className={styles.disclaimerText}>
          {lineItems?.length
            ? 'Only one Reward may be redeemed per purchase.'
            : 'Add at least one item to your cart to redeem a reward.'}
        </p>
      </div>
      <RewardList
        disableRedeemButtons={!lineItems?.length}
        lineItems={lineItems}
        onOfferRedeemedSuccess={onOfferRedeemed}
        onOffersMarkedAsSeen={onOffersMarkedAsSeen}
        runCondition={isOpen}
      />
    </Modal>
  );
};
