import { handleProtocolRelativeUrl } from 'lib/contentful/utils';
import { cloudinaryFetchBaseUrl } from 'lib/image';
/**
 * This image preloader will cause the browser to load this image.
 * Otherwise we need to wait to load & paint our background-image in CSS.
 */
export const renderImagePreloader = (imageUrl: string) => (
  // eslint-disable-next-line jsx-a11y/alt-text
  <img src={imageUrl} style={{ display: 'none' }} />
);

export const gifToVideoFile = (imageUrl: string) => {
  const mp4Url = `${cloudinaryFetchBaseUrl}/f_mp4/${handleProtocolRelativeUrl(
    imageUrl
  )}`;
  const webmUrl = `${cloudinaryFetchBaseUrl}/f_webm/${handleProtocolRelativeUrl(
    imageUrl
  )}`;
  return {
    mp4Url,
    webmUrl,
  };
};
