import { ShopifyCart } from 'data/graphql/types.shopify';

import Logger from 'lib/utils/Logger';

import { Cart as CartType } from './graphql/types';

const convertFromShopifyCartFormat = (
  apiData: ShopifyCart
): CartType | undefined => {
  try {
    const {
      appliedGiftCards,
      currencyCode,
      discountApplications: { edges: discounts },
      id,
      lineItems: { edges: items },
      lineItemsSubtotalPrice,
      orderStatusUrl,
      subtotalPriceV2,
      totalPrice,
      totalTax,
      webUrl = '',
    } = apiData;

    const discountApplications =
      discounts && discounts.map(({ node }: any) => ({ ...node }));

    const lineItems = items.map(({ node }: any) => ({
      ...node.variant, // move all Variant props up a level
      compareAtPrice: node.variant.compareAtPrice?.amount,
      price: node.variant.price.amount,
      variantId: node.variant.id,
      vendor: node.variant.product.vendor, // promote Vendor property
      ...node,
    }));

    return {
      appliedGiftCards,
      currencyCode,
      discounts: discountApplications,
      id,
      lineItems,
      lineItemsSubtotalPrice,
      orderStatusUrl,
      subtotalPrice: subtotalPriceV2,
      totalPrice: totalPrice.amount,
      totalTax: totalTax.amount,
      webUrl,
    };
  } catch (error) {
    Logger.error('Error in convertFromShopifyCartFormat', error);
    return undefined;
  }
};

export default convertFromShopifyCartFormat;
