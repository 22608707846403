import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Fragment, MouseEvent } from 'react';

import SectionSubmenuItem from './SectionSubmenuItem';
import BrandLetterLinksIndex from 'components/BrandLetterLinksIndex/BrandLetterLinksIndex';
import IDefaultComponentProps from 'components/defaultComponentProps';
import LocalizableLink, {
  ILocalizableLinkProps,
} from 'components/LocalizableLink/LocalizableLink';
import { NavigationSubmenuSectionProps } from 'components/NavigationMenu/NavigationSubmenuSection';

import { NavSubmenuSectionTypes } from 'lib/contentful';

import styles from './SectionSubmenu.module.scss';

export type SectionSubmenuProps = {
  onLinkClick?: (event: MouseEvent<HTMLElement>) => void;
} & NavigationSubmenuSectionProps &
  IDefaultComponentProps;

const SectionSubmenu = ({
  className,
  items,
  onLinkClick,
  summaryLinkProps,
  type,
}: SectionSubmenuProps) => {
  return (
    <div className={classnames(styles.root, className)}>
      <div className={styles.backButton} />
      {renderSubmenuContent({ items, onLinkClick, summaryLinkProps, type })}
    </div>
  );
};

export type RenderSubmenuContentPops = {
  items?: ILocalizableLinkProps[];
  onLinkClick?: (event: MouseEvent<HTMLElement>) => void;
  summaryLinkProps?: ILocalizableLinkProps;
  type: NavSubmenuSectionTypes;
};

export const renderSubmenuContent = ({
  items = [],
  onLinkClick,
  summaryLinkProps,
  type,
}: RenderSubmenuContentPops) => {
  // If the submenu is Brands Index Anchors, render it differently from other submenu types
  if (type === NavSubmenuSectionTypes.BRANDS_SHORTCUTS) {
    return (
      <Fragment key={summaryLinkProps?.id}>
        {summaryLinkProps && (
          <SectionSubmenuItem onClick={onLinkClick} showSubmenuExpander={false}>
            <LocalizableLink
              {...summaryLinkProps}
              className={styles.menuItem}
            />
          </SectionSubmenuItem>
        )}
        <BrandLetterLinksIndex
          className={styles.letterLinksIndex}
          onClick={onLinkClick}
        />
      </Fragment>
    );
  }
  if (isEmpty(items)) {
    return null;
  }

  return (
    <Fragment key={`submenuContent_${items[0].id}`}>
      {items.map((item: ILocalizableLinkProps, index: number) => (
        <div
          key={`${index}_${item.id}`}
          onClick={onLinkClick}
          role="presentation"
        >
          <LocalizableLink {...item} className={styles.menuItem} />
        </div>
      ))}
    </Fragment>
  );
};

export default SectionSubmenu;
