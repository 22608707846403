import {
  EXPIRES_IN_30_DAYS_IN_UNIT_SECOND,
  getCookie,
  setCookie,
} from './cookieWrapper';

import { isBrowser } from 'lib/utils/browser';
import Logger from 'lib/utils/Logger';

const PARAM_UTM_CAMPAIGN = 'utm_campaign';
const PARAM_UTM_SOURCE = 'utm_source';
const UTM_SOURCE_CREATOR = 'creator';

// SourceAffiliateLinkOwner is owner of 'affiliate link' from which user clicks in.
export const SOURCE_AFFILIATE_LINK_OWNER_KEY =
  'verishop.sourceAffliateLinkOwner';

/**
 * TODO(ningchong): EC-1216 get sourceAffiliateLinkOwner and set in order notes.
 */
export const getSourceAffiliateLinkOwner = (): string | undefined => {
  return getCookie(SOURCE_AFFILIATE_LINK_OWNER_KEY);
};

const setSourceAffiliateLinkOwnerInCookie = (value: string): void => {
  setCookie(
    SOURCE_AFFILIATE_LINK_OWNER_KEY,
    value,
    EXPIRES_IN_30_DAYS_IN_UNIT_SECOND
  );
};

export const setSourceAffiliateLinkOwner = (): void => {
  if (!isBrowser()) {
    return;
  }
  try {
    const url = new URL(window.location.href);
    if (url.searchParams.get(PARAM_UTM_SOURCE) === UTM_SOURCE_CREATOR) {
      const sourceOwnerData = JSON.stringify({
        owner: url.searchParams.get(PARAM_UTM_CAMPAIGN),
        ts: new Date(),
        url,
      });
      setSourceAffiliateLinkOwnerInCookie(sourceOwnerData);
    }
  } catch (error) {
    Logger.error('error setSourceAffiliateLinkOwner in Cookie: ', error);
  }
};
