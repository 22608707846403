import classnames from 'classnames';
import isNil from 'lodash/isNil';
import throttle from 'lodash/throttle';
import { useEffect, useState } from 'react';

import Header from './Header';
import IDefaultComponentProps from 'components/defaultComponentProps';

import { isBrowser } from 'lib/utils/browser';

import styles from './HeaderContainer.module.scss';

export enum SCROLL_STATE {
  AT_TOP = 'at_top',
  SCROLLED_DOWN = 'scrolled_down',
  SCROLLED_UP = 'scrolled_up',
  UNKNOWN = 'unknown', // when page is navigated to, it could have old scroll position
}

export const HEADER_CONTAINER_ID = 'header-nav';
const SCROLL_DIRECTION_BUFFER = 100;
const SCROLL_THROTTLE = 200;

export type HeaderContainerProps = {
  openNewsletterModal: () => void;
  searchTerm?: string;
} & IDefaultComponentProps;

const HeaderContainer = ({
  className,
  openNewsletterModal,
  searchTerm,
}: HeaderContainerProps) => {
  const [scrollState, setScrollState] = useState(SCROLL_STATE.UNKNOWN);
  const [pageYOffset, setPageYOffset] = useState(0);

  const updateScrollStateWithoutRefresh = (
    newScrollState: SCROLL_STATE,
    newPageYOffset: number
  ) => {
    // update pageYOffset to new value:
    setPageYOffset(newPageYOffset);
    if (
      scrollState !== SCROLL_STATE.UNKNOWN &&
      newScrollState === scrollState
    ) {
      return; // if not changed, noop;
    }
    setScrollState(newScrollState);
  };

  const handleScroll = throttle(() => {
    if (typeof window !== 'undefined') {
      const newPageYOffset = window.scrollY || 0;

      // if at the top of the page or unknown, show full Header
      if (
        isNil(newPageYOffset) ||
        (newPageYOffset <= SCROLL_DIRECTION_BUFFER &&
          scrollState !== SCROLL_STATE.AT_TOP)
      ) {
        updateScrollStateWithoutRefresh(
          SCROLL_STATE.AT_TOP,
          newPageYOffset || 0
        );
      } else if (
        newPageYOffset >
        Number(pageYOffset) + SCROLL_DIRECTION_BUFFER
      ) {
        // if scrolled down by more than a buffer, collapse header to min mode
        updateScrollStateWithoutRefresh(
          SCROLL_STATE.SCROLLED_DOWN,
          newPageYOffset
        );
      } else if (
        newPageYOffset <
        Number(pageYOffset) - SCROLL_DIRECTION_BUFFER
      ) {
        // if scrolled up by more than a buffer, expand header to full mode
        updateScrollStateWithoutRefresh(
          SCROLL_STATE.SCROLLED_UP,
          newPageYOffset
        );
      }
    }
  }, SCROLL_THROTTLE);

  useEffect(() => {
    if (!isBrowser()) {
      return;
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
      setPageYOffset(window.scrollY);
    }

    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const headerProps = {
    openNewsletterModal,
    scrollState,
    searchTerm,
  };

  return (
    <div
      className={classnames(className, styles.headerContainer)}
      id={HEADER_CONTAINER_ID}
    >
      <Header {...headerProps} />
    </div>
  );
};

export default HeaderContainer;
