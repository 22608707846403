import classnames from 'classnames';

import { BUTTON_SIZE } from 'components/Buttons/ButtonBase';

import styles from './SpinnerInlineCSS.module.scss';

type SpinnerInlineCSSProps = {
  className?: string;
  isWhite?: boolean;
  size?: BUTTON_SIZE;
};

const SpinnerInlineCSS = ({
  className,
  isWhite,
  size = BUTTON_SIZE.LARGE,
}: SpinnerInlineCSSProps) => (
  <div
    className={classnames([
      styles.spinner,
      {
        [styles.isSmall]: size === BUTTON_SIZE.SMALL,
        [styles.isMedium]: size === BUTTON_SIZE.MEDIUM,
        [styles.isLarge]: size === BUTTON_SIZE.LARGE,
        [styles.isWhite]: isWhite,
      },
      className,
    ])}
  />
);

export default SpinnerInlineCSS;
