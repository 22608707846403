import { isSameDay } from 'date-fns';

import getConfig from 'config/config';
import { LiveShopProductCardProps } from 'explorer/containers/LiveShopContainer/liveShopProductCard';

import textFormatter from 'lib/textFormatter';
import { getLocalTimeZoneCode } from 'lib/utils/date';
import Logger from 'lib/utils/Logger';

import {
  LiveShopStatus,
  LiveShopWithProductsNoAuthFieldsFragment,
} from 'types/generated/api';
import { ProductEventData } from 'types/segment';

const BASE_URL = getConfig('baseUrl');

export const getParsedFeaturedProducts = (
  featuredProducts: LiveShopWithProductsNoAuthFieldsFragment['featuredProducts']
): LiveShopProductCardProps[] => {
  const parsedFeaturedProducts: LiveShopProductCardProps[] = [];
  featuredProducts?.forEach(featuredProductVariant => {
    const brand = featuredProductVariant?.productVariant?.product?.brand;
    const productSid = featuredProductVariant?.productVariant?.product?.sid;
    const familySlug =
      featuredProductVariant?.productVariant?.product?.familySlug;
    const brandSlug =
      featuredProductVariant?.productVariant?.product?.brandSlug;
    const slug = featuredProductVariant?.productVariant?.product?.slug;

    const compareAtPrice =
      featuredProductVariant?.productVariant?.compareAtPrice;
    const price = featuredProductVariant?.productVariant?.price;
    const productImageUrl = featuredProductVariant?.productVariant
      ?.galleryAssets?.[0]?.url as string; // TODO should we make image optional in web foundations?
    const productName =
      featuredProductVariant?.productVariant?.title ??
      featuredProductVariant?.productVariant?.product?.title;

    if (!brand || !compareAtPrice || !price || !productName) {
      return;
    }

    // TODO update live shop product card logic to
    // show PriceInfo component if item is potentially on sale
    // and then extra 15% off with liveshop
    const formattedPrice = textFormatter.formatCurrency(price);
    const formattedLiveShopPrice = textFormatter.formatCurrency(price * 0.85);

    parsedFeaturedProducts.push({
      brand,
      clickUrl: `${BASE_URL}/${brandSlug}/${familySlug}/${slug}/p${productSid}`,
      compareAtPrice: formattedPrice,
      liveShopPrice: formattedLiveShopPrice,
      productImageUrl,
      productName,
    });
  });

  return parsedFeaturedProducts;
};

export const formatStartsAtLabel = (
  startsAtDate: Date,
  referenceDate: Date = new Date()
): string => {
  let dateCopy = '';
  if (isSameDay(startsAtDate, referenceDate)) {
    dateCopy = 'Today';
  } else {
    dateCopy = startsAtDate.toLocaleDateString();
  }

  const timeCopy = startsAtDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
  const timeZone = getLocalTimeZoneCode();

  return `${dateCopy} at ${timeCopy} ${timeZone}`;
};

export const isLiveShopExpired = (
  endDateTimeString?: string | null,
  liveShopStatus?: LiveShopStatus
): boolean => {
  try {
    // prefer the live shop status for arriving at a return value, fallback to using the end time
    if (liveShopStatus) {
      return liveShopStatus === LiveShopStatus.ENDED;
    }
    if (endDateTimeString) {
      const endsDate = new Date(endDateTimeString);
      return endsDate.valueOf() < Date.now().valueOf();
    }
  } catch (error) {
    if (error instanceof Error) {
      Logger.error(
        'Unable to determine expiration status of LiveShop event. Error: ',
        error
      );
    }
  }
  return false;
};

export const hasLiveShopStarted = (
  startDateTimeString?: string | null
): boolean => {
  try {
    if (!startDateTimeString) {
      return false;
    }
    const startDate = new Date(startDateTimeString);
    return startDate.valueOf() < Date.now().valueOf();
  } catch (error) {
    if (error instanceof Error) {
      Logger.error(
        'Unable to determine started status of LiveShop event. Error: ',
        error
      );
    }
  }
  return false;
};

/* 
  Handles displaying label that specifies whether the event is live, 
  has ended, or has yet to start
*/

export const formatIsPlayingHeader = (
  endsAt: string,
  startsAt: string,
  isExpired?: boolean
): string => {
  const timeCopy = formatStartsAtLabel(new Date(isExpired ? endsAt : startsAt));

  return `${isExpired ? 'Ended ' : ''}${timeCopy}`;
};

export const getLiveEventStatus = (
  isLive: boolean,
  isExpired: boolean
): 'ongoing' | 'post' | 'pre' => {
  if (isLive) {
    return 'ongoing';
  } else if (isExpired) {
    return 'post';
  }

  return 'pre';
};

export const getPriceFromProductCard = (
  productCard: Pick<
    LiveShopProductCardProps,
    'liveShopPrice' | 'compareAtPrice'
  >
): number => {
  try {
    return (
      Number.parseFloat(
        (productCard.liveShopPrice || productCard.compareAtPrice || '').match(
          /[\d|.]+/ // remove all non-digit chars, like $
        )?.[0] || ''
      ) || NaN
    );
  } catch (error) {
    return NaN;
  }
};

export interface LiveShopProductClickEventData extends ProductEventData {
  source?: string;
}

export const generateProductCardClickEventData = (
  products: LiveShopWithProductsNoAuthFieldsFragment['featuredProducts'],
  card: LiveShopProductCardProps,
  index: number
): LiveShopProductClickEventData | null => {
  try {
    // Find a matching product by index:
    // Making an assumption that products are always in the same order,
    // if not, we can do some lookup on titles, but it can cause false matches
    const productVariant = products[index].productVariant;
    const product = productVariant?.product;

    const price: number =
      getPriceFromProductCard(card) || productVariant.price || NaN;

    const productEventData: LiveShopProductClickEventData = {
      brand: product?.brand || card.brand,
      category: product?.familySlug || '',
      image_url: card.productImageUrl,
      name: productVariant.title || card.productName,
      position: index,
      price,
      product_id: product?.sid || '',
      // sku: not available
      url: card.clickUrl,
    };

    return productEventData;
  } catch (error) {
    if (error instanceof Error) {
      Logger.warn(
        'Something went wrong when trying to generate ProductCard click event on LiveShop',
        error
      );
    }
  }

  return null;
};
