import gql from 'graphql-tag';

export const RewardCenterModalQuery = gql`
  query webOfferCenterGetVerifiedDeals($cursor: String, $limit: Int) {
    verifiedDeals(cursor: $cursor, limit: $limit) {
      cursor
      deals {
        appliedShopifyGiftCardId
        createdAt
        description
        expiresAt
        id
        isSeen
        liveShopId
        maximumOrderCount
        minimumSpend
        pictureAsset {
          url
        }
        productIds
        shopPartyIds
        shopifyGiftCardCode
        shopifyGiftCardId
        shopifyIds
        shopifyOrderId
        skus
        status
        type
        unlocksAt
        userId
        value
        valueType
      }
    }
  }
`;

export const RedeemRewardMutation = gql`
  mutation redeemVerifiedDeal($input: RedeemVerifiedDealInput!) {
    redeemVerifiedDeal(input: $input) {
      shopifyGiftCardCode
      shopifyGiftCardId
      shopifyGiftCardValue
      success
      validationErrors {
        code
        field
        message
      }
    }
  }
`;

export const SaveAppliedShopifyGiftCardIdMutation = gql`
  mutation webSaveAppliedShopifyGiftCardId(
    $input: saveAppliedShopifyGiftCardIdInput!
  ) {
    saveAppliedShopifyGiftCardId(input: $input) {
      success
      validationErrors {
        code
        field
        message
      }
    }
  }
`;
