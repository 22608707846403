import classnames from 'classnames';
import { KeyboardEvent } from 'react';

import styles from './RadioButton.module.scss';

type RadioButtonProps = {
  className?: string;
  isChecked?: boolean;
  label: string;
  onClick: () => void;
  tabIndex?: number;
};

const RadioButton = (props: RadioButtonProps) => {
  const { className, isChecked, label, onClick, tabIndex } = props;
  const containerClassName = classnames(styles.root, className);
  const inputClassName = classnames(styles.input, {
    [styles.isChecked]: isChecked,
  });

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  return (
    <div
      aria-checked={isChecked}
      className={containerClassName}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      role="radio"
      tabIndex={tabIndex}
    >
      <div className={inputClassName} />
      <span className={styles.label}>{label}</span>
    </div>
  );
};

RadioButton.defaultProps = {
  tabIndex: 0,
};

export default RadioButton;
