import classnames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import useScrollLock from 'lib/hooks/scrollLock/useScrollLock';

import CloseIcon from 'assets/icons/ic-clear.inline.svg';

import styles from './PageTakeover.module.scss';

type PageTakeoverProps = {
  children: ReactNode;
  className?: string;
  footerComponent?: ReactNode;
  headerText?: string;
  hideHeader?: boolean;
  isOpen: boolean;
  onCloseClick: () => void;
};

const PageTakeover = (props: PageTakeoverProps) => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  const {
    children,
    className,
    footerComponent,
    headerText,
    hideHeader,
    isOpen,
    onCloseClick,
  } = props;

  const { refCallback } = useScrollLock(isOpen);

  // ReactDOM.createPortal is not supported on SSR we have to do this in order to not get the following warning:
  // index.js:1 Warning: Expected server HTML to contain a matching <div> in <div>. in div (at PageTakeover.tsx:55)
  if (!hasMounted) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={classnames(styles.root, className, {
        [styles.show]: isOpen,
        [styles.hide]: !isOpen,
      })}
    >
      {!hideHeader && (
        <div className={styles.header}>
          <div className={styles.headerSection} />
          <div className={styles.headerSection}>{headerText}</div>
          <div className={classnames(styles.headerSection, styles.alignRight)}>
            <CloseIcon className={styles.closeIcon} onClick={onCloseClick} />
          </div>
        </div>
      )}

      <div className={styles.content} ref={refCallback}>
        {children}
      </div>

      {footerComponent && footerComponent}
    </div>,
    document.body as Element
  );
};

export default PageTakeover;
