import { useMutation } from '@apollo/client';
import idx from 'idx';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';

import VerishopAgreementText from 'components/Blurbs/VerishopAgreementText/VerishopAgreementText';
import { BUTTON_SIZE } from 'components/Buttons/ButtonBase';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import CopyToClipboard from 'components/CopyToClipboard/CopyToClipboard';
import RadioButton from 'components/InputFields/RadioButton/RadioButton';
import TextInput from 'components/InputFields/TextInput/TextInput';
import Modal from 'components/Modals/Modal/Modal';

import {
  trackNewsletterSignupSuccess,
  trackNewsletterSignupViewed,
} from 'lib/analytics';
import {
  getQueryParamsFromBrowser,
  isBrowser,
  setOpenNewsletterModal,
} from 'lib/utils/browser';
import Logger from 'lib/utils/Logger';

import { EMAIL_LIST_SIGN_UP } from './NewsletterModal.gql';

import {
  CreateEmailSignUpMutation,
  CreateEmailSignUpMutationVariables,
} from 'types/generated/api';

import styles from './NewsletterModal.module.scss';

const COPY_BUTTON_TEXT = 'Copy';
const COPY_BUTTON_TEXT_PRESSED = 'Copied';
const COUPON_CODE = 'VERISHOP10';
const COUPON_DISCLAIMER =
  "Discount valid for new customers only and applies to full-priced women's and men's items only. See site for brand exclusions.";
const INCORRECT_EMAIL_ERROR_MESSAGE = 'Your email address is invalid.';
const MODAL_HEADER = 'Get 10% Off Your First Order!';

export const MODAL_POPUP_DELAY = 3000;

enum NewsletterTypes {
  All = 'All',
  Men = 'Men',
  Women = 'Women',
}

type NewsletterModalProps = {
  closeModal: () => void;
  isOpen: boolean;
  openModal: () => void;
};

export const NewsletterModal = ({
  closeModal,
  isOpen,
  openModal,
}: NewsletterModalProps) => {
  const [addToEmailList] = useMutation<
    CreateEmailSignUpMutation,
    CreateEmailSignUpMutationVariables
  >(EMAIL_LIST_SIGN_UP);
  const [selectedNewsLetter, setSelectedNewsLetter] = useState<NewsletterTypes>(
    NewsletterTypes.All
  );
  const [copySuccess, setCopySuccess] = useState(false);
  const [successfulSignUp, setSuccessfulSignUp] = useState(false);
  const [emailText, setEmailText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const buttonText = successfulSignUp ? 'Subscribed!' : 'Subscribe';
  const isMensNewsletterSelected = selectedNewsLetter === NewsletterTypes.Men;
  const isWomensNewsletterSelected =
    selectedNewsLetter === NewsletterTypes.Women;
  const areAllNewslettersSelected = selectedNewsLetter === NewsletterTypes.All;

  // Sets a global function for opening the modal via GTM
  useEffect(() => {
    if (openModal) {
      setOpenNewsletterModal(openModal);
    }
  }, [openModal]);

  // Open modal if 'newsletterModal' is in query-params
  useEffect(() => {
    if (!isBrowser()) {
      return;
    }

    const queryParams = getQueryParamsFromBrowser();
    if (queryParams.newsletterModal) {
      window.setTimeout(openModal, MODAL_POPUP_DELAY);
    }
  }, [openModal]);

  useEffect(() => {
    if (isOpen) {
      trackNewsletterSignupViewed();
    }
  }, [isOpen]);

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmailText(event.target.value);
  };

  const buildAddToEmailListVariables = () => {
    return {
      input: {
        email: emailText,
        subscribeToMens: isMensNewsletterSelected || areAllNewslettersSelected,
        subscribeToWomens:
          isWomensNewsletterSelected || areAllNewslettersSelected,
      },
    };
  };

  const onSubscribePress = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const variables = buildAddToEmailListVariables();
      const result = await addToEmailList({ variables });
      const isSuccess =
        result && idx(result, _ => _.data.createEmailSignUp.success);

      if (isSuccess) {
        setSuccessfulSignUp(true);
        setErrorMessage('');
        trackNewsletterSignupSuccess({ email: emailText });
      } else if (isSuccess === false) {
        setSuccessfulSignUp(false);
        setErrorMessage(INCORRECT_EMAIL_ERROR_MESSAGE);
      }
    } catch (error) {
      setSuccessfulSignUp(false);
      setErrorMessage('Sorry, something went wrong. Please try agian.');
      Logger.error('Error signing up for newsletter', error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderCoupon = () => {
    return (
      <div className={styles.successContainer}>
        <div className={styles.successBlurb}>Apply your code at checkout</div>
        <div className={styles.codeAndCopyContainer}>
          <div className={styles.couponCode}>{COUPON_CODE}</div>
          <div className={styles.divider}>—</div>
          <CopyToClipboard
            onCopy={() => setCopySuccess(true)}
            value={COUPON_CODE}
          >
            <div className={styles.copyButton}>
              {copySuccess ? COPY_BUTTON_TEXT_PRESSED : COPY_BUTTON_TEXT}
            </div>
          </CopyToClipboard>
        </div>
      </div>
    );
  };

  return (
    <Modal
      aria-level="2"
      className={styles.modalRoot}
      header={MODAL_HEADER}
      isOpen={isOpen}
      onCloseClick={closeModal}
      role="heading"
    >
      <div className={styles.blurb}>
        Sign up to be the first to hear about new arrivals and offers.
      </div>
      <form method="post" onSubmit={onSubscribePress}>
        <TextInput
          aria-label="Email Address"
          className={styles.textInput}
          errorMessage={errorMessage}
          onChange={handleEmailChange}
          placeholder="Email address"
          value={emailText}
        />
        <div className={styles.optionsContainer}>
          <RadioButton
            isChecked={isMensNewsletterSelected}
            label="Men"
            onClick={() => setSelectedNewsLetter(NewsletterTypes.Men)}
          />
          <RadioButton
            className={styles.optionSpacing}
            isChecked={isWomensNewsletterSelected}
            label="Women"
            onClick={() => setSelectedNewsLetter(NewsletterTypes.Women)}
          />
          <RadioButton
            className={styles.optionSpacing}
            isChecked={areAllNewslettersSelected}
            label="Both"
            onClick={() => setSelectedNewsLetter(NewsletterTypes.All)}
          />
        </div>
        <PrimaryButton
          className={styles.button}
          isLoading={isLoading}
          isSuccess={successfulSignUp}
          size={BUTTON_SIZE.LARGE}
          text={buttonText}
          type="submit"
        />
        {successfulSignUp && renderCoupon()}

        <div className={styles.disclaimer}>{COUPON_DISCLAIMER}</div>
        <VerishopAgreementText
          actionText="clicking Subscribe"
          className={styles.agreementText}
        />
      </form>
    </Modal>
  );
};

export default NewsletterModal;
