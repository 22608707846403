import classnames from 'classnames';
import {
  ButtonHTMLAttributes,
  FunctionComponent,
  ReactElement,
  SVGAttributes,
} from 'react';

import styles from './IconButton.module.scss';

export type IconButtonProps = {
  IconComponent: FunctionComponent<SVGAttributes<SVGElement>>;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const IconButton = ({
  IconComponent,
  className,
  ...buttonProps
}: IconButtonProps): ReactElement => {
  return (
    <button {...buttonProps} className={classnames(styles.root, className)}>
      <IconComponent className={styles.icon} />
    </button>
  );
};

export default IconButton;
