import { useCallback, useEffect, useReducer } from 'react';
import { MouseEvent } from 'react';

import { trackEvent } from 'lib/analytics';
import AnalyticsEvent from 'lib/analytics/events';
import { EXPLORER_POST_APP_URL as IOS_APP_DOWNLOAD_URL } from 'lib/ios/constants';

type AppInstallState = {
  didClickDownload: boolean;
  didTrackOpen: boolean;
};

type AppInstallAction =
  | { type: 'TRACK_OPEN' }
  | { type: 'USER_CLICK_DOWNLOAD' };

const initialState: AppInstallState = {
  didClickDownload: false,
  didTrackOpen: false,
};

const AppInstallReducer = (
  state: AppInstallState,
  action: AppInstallAction
): AppInstallState => {
  switch (action.type) {
    case 'TRACK_OPEN': {
      return {
        ...state,
        didTrackOpen: true,
      };
    }

    case 'USER_CLICK_DOWNLOAD': {
      return {
        ...state,
        didClickDownload: true,
      };
    }

    default: {
      return state;
    }
  }
};

type UseAppInstallReturnValue = AppInstallState & {
  onCloseClick: () => void;
  onDownloadClick: () => void;
};

type AppInstallProps = {
  inviteCode?: string;
  isOpen: boolean;
  onClose: (event?: MouseEvent<HTMLElement>) => void;
  onCloseClickEvent?: AnalyticsEvent;
  onDownloadClickEvent?: AnalyticsEvent;
  onOpenEvent?: AnalyticsEvent;
};

export const useAppInstall = ({
  inviteCode,
  isOpen,
  onClose,
  onCloseClickEvent,
  onDownloadClickEvent,
  onOpenEvent,
}: AppInstallProps): UseAppInstallReturnValue => {
  const [state, dispatch] = useReducer(AppInstallReducer, initialState);

  const onDownloadClick = useCallback(() => {
    if (inviteCode) {
      if (onDownloadClickEvent) {
        trackEvent(onDownloadClickEvent);
      }
      const probableWebURLForIOS = `verishop://invitecode:${inviteCode}`;
      dispatch({ type: 'USER_CLICK_DOWNLOAD' });
      if (navigator.clipboard) {
        try {
          navigator.clipboard.writeText(probableWebURLForIOS);
        } catch {
          window.prompt('Please copy your invite code: ', inviteCode);
        }
      }
      window.location.href = IOS_APP_DOWNLOAD_URL;
    }
  }, [inviteCode, onDownloadClickEvent]);

  const onCloseClick = useCallback(() => {
    if (onCloseClickEvent) {
      trackEvent(onCloseClickEvent);
    }
    onClose();
  }, [onClose, onCloseClickEvent]);

  /**
   * Use an effect to track when this component is open/shown.
   * If this component is open, closed then open, it tracks twice on each open.
   */
  useEffect(() => {
    if (isOpen) {
      if (onOpenEvent) {
        trackEvent(onOpenEvent);
      }
      dispatch({ type: 'TRACK_OPEN' });
    }
  }, [isOpen, onOpenEvent]);

  return { ...state, onCloseClick, onDownloadClick };
};
